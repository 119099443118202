import { NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Card, Page } from '@shopify/polaris';
import { MenuGroupDescriptor } from "@shopify/polaris/types";
import { useI18n } from "@shopify/polaris/utilities/i18n";
import { UIService, isTable, ok } from "common";
import { DataService } from "data-service";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAngular, useLoadingMarkup, useObserver, useRefresh } from "react-utils";
import { TableListInner } from "../tables/TableListInner";
import { getTableViews } from "../tables/table-views";
import { FormsQuestionService, LedgerTables } from '../utils';


export function ListTablePage() {
  const { table } = useParams();
  ok(isTable(table), "Invalid table: " + table);
  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);
  const router = get(Router);
  const zone = get(NgZone);
  const actionGroups: MenuGroupDescriptor[] = [];

  const i18n = useI18n();

  const [counter, setCounter] = useState(0);

  useObserver(useRefresh(), () => { setCounter(count => count + 1); });

  const views = useMemo(() => getTableViews(table) ?? [], [table]);
  const title = pluralize(table);


  const onSelectRow = async (id: string | undefined) => {
    console.log("onSelectRow", table, id);
    if (table === "BranchUser" || table === "User") {
      if (!id) {
        const dialog = await fq.onClickFindUser();
        if (dialog) dialog.onSaveSuccess.subscribe(async (e) => {
          dialog.subs.unsubscribe();
          if (e.table === "User" && e.id) {
            await fq.onClickEditUser(e.id);
          }
        });
      } else {
        await fq.onClickEditUser(id);
      }
    } else {
      if (id) fq.onClickEvent({ action: "edit", table, id })
      else fq.onClickEvent({ action: "add", table, params: {} })
    }
  };


  const primaryAction = [...LedgerTables, "Rental", "UserPermission"].contains(table) ? null : {
    content: 'Add ' + table, onAction: () => { onSelectRow(undefined); }, disabled: false,
  };

  const loadingMarkup = useLoadingMarkup(pluralize(table).toLocaleLowerCase());

  return (
    <Page fullWidth title={title} primaryAction={primaryAction} actionGroups={actionGroups}>
      <Card padding="0">
        <TableListInner
          key={table + counter}
          table={table}
          views={views}
          loadingMarkup={loadingMarkup}
          onSelectRow={onSelectRow} />
      </Card>
    </Page>
  );
}
