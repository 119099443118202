import "./schema-builder/globals";
import { ConfirmType, ID, PostgresUserRoles, String, } from "./schema-builder/cubes-amazon-old";
import { DataSchema, schema as _schema, SPPI, TYPE_NAMES, getPathsFromObject, RealType, TABLE_NAMES, ARRAY_PATH_SYMBOL, SelectTypeTree, ToSelectObject, } from "./schema-builder/cubes-index";
import { Anything, AnyMember, Directive, EnumType, IsArray, MemberKeys, ScalarType } from "./schema-builder/graphql-declarator";
import { RecordType, RecordMember, Wrapping } from "./schema-builder/graphql-declarator";
import { TableType } from "./schema-builder/cubes-schema-helpers";
// import "./data-calculator/index";
// we do not * export the entire thing from here
// export type { PrismaPromise } from "./prisma/client";
// export { Prisma, PrismaClient } from "./prisma/client";
// export * as PrismaExtra from "prisma-client";
export * from "./schema-builder/cubes-index";
export * from "./schema-builder/cubes-schema";
export type { SRData, SRKeys, SRResult, SRMap } from "./server/Application/ServerRequestsUser";
export type { CRData, CRKeys, CRResult, } from "./server/Handler/ClientRequests";
export type { PaymentFormKey, PaymentFormInput, PaymentFormOutput } from "./server/Handler/handleCustomerRequests";
// export { ServerStatus } from "./server/handleServerRequests";
export * from "./datamath-server";
export * from "./date-funcs"
export { __rents as CONSTANTS } from "./schema-builder/cubes-const";
export type { MemberKeys, QueryTree, ValueTree, } from './schema-builder/graphql-declarator';
import { Member } from "./schema-builder/cubes-schema-helpers";
import { DefaultArgs, GetResult } from "@prisma/client/runtime/library";
import { Prisma } from "prisma-client";
import { Rental } from "./schema-builder/cubes-schema";
export type { ServerStatus } from "./server/Utils/ServerFunctions";
export * from "./schema-builder/graphql-operations";
export type { Anything, AnyMember, RecordMember, Directive, EnumType, RecordType, ScalarType, Wrapping, IsArray };
export { PrismaQuery } from "./PrismaQuery";
export { typesFuncBody } from "./schema-builder/cubes-helpers";
export { WHERE_balanceWhereLine } from "./schema-builder/cubes-schema-from-prisma";
export { BaseLedger } from './schema-builder/cubes-schema-helpers';
export const schema: DataSchema = _schema;
export type Modes = MemberKeys<ConfirmType>;
export type Roles = MemberKeys<PostgresUserRoles> | null;
export { TableType };
export function checkAmplifyFilter(filter: any, value: any): boolean {
  enum ModelAttributeTypes {
    binary = "binary",
    binarySet = "binarySet",
    bool = "bool",
    list = "list",
    map = "map",
    number = "number",
    numberSet = "numberSet",
    string = "string",
    stringSet = "stringSet",
    _null = "_null"
  }

  type EqualityKeys = "eq" | "ne";
  type AngleKeys = "ge" | "gt" | "le" | "lt";
  type ModelAttributeInput = {
    attributeExists?: boolean | null;
    attributeType?: ModelAttributeTypes | null;
  }
  type ModelFloatInput = ModelAttributeInput & Record<EqualityKeys | AngleKeys | "between", any>
  type ModelIntInput = ModelAttributeInput & Record<EqualityKeys | AngleKeys | "between", any>
  type ModelStringInput = ModelAttributeInput & Record<EqualityKeys | AngleKeys | "beginsWith" | "notContains" | "contains", any>
  type ModelBooleanInput = ModelAttributeInput & Record<EqualityKeys, any>;

  type ModelNumberInput = ModelFloatInput & ModelIntInput;
  type ModelEnumInput<T> = {
    eq?: T;
    ne?: T;
  }
  type Filter<T> =
    T extends RecordType ? { [K in MemberKeys<T> & keyof T]: Filter<T[K]> } :
    T extends EnumType ? ModelEnumInput<MemberKeys<T>> :
    T extends ScalarType<string> ? ModelStringInput :
    T extends ScalarType<number> ? ModelNumberInput :
    T extends ScalarType<boolean> ? ModelBooleanInput :
    never;

  if (filter.eq) return value === filter.eq;
  if (filter.ne) return value !== filter.ne;
  if (filter.ge) return value >= filter.ge;
  if (filter.gt) return value > filter.gt;
  if (filter.le) return value <= filter.le;
  if (filter.lt) return value < filter.lt;

  if (filter.between)
    throw new Error("filter between not implemented");

  if (filter.beginsWith != null)
    return value.startsWith(filter.beginsWith);
  if (filter.contains != null)
    return value.indexOf(filter.contains) !== -1;
  if (filter.notContains)
    return value.indexOf(filter.notContains) === -1;
  if (filter.size)
    return checkAmplifyFilter(filter.size, value.length);

  if (filter.attributeExists != null) {
    return (value != null) === !!filter.attributeExists;
  }

  if (filter.attributeType != null) {
    switch (filter.attributeType) {
      case ModelAttributeTypes.string: return typeof value === "string";
      case ModelAttributeTypes.number: return typeof value === "number" || typeof value === "bigint";
      case ModelAttributeTypes.bool: return typeof value === "boolean";
      case ModelAttributeTypes._null: return value === null || value === undefined;
      case ModelAttributeTypes.list: return Array.isArray(value);
      case ModelAttributeTypes.map: return typeof value === "object";
      default: throw new Error(`filter attributeType ${filter.attributeType} not implemented`);
    }
  }

  return true;
}

export const isEmptyObject = (objectName: any) => {
  for (var prop in objectName) {
    if (objectName.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
}

export { UnwrapColumns, ToSelectObject } from "./schema-builder/cubes-utils";

// type Filter<COLS, FILTER> = {
//   [KEY in keyof COLS]:
//   COLS[KEY] extends [infer FIRST extends FILTER, ...infer REST] ? REST : never
// }
// export type ToSelectObject<COLS extends readonly (SPPI | { key: SPPI })[]> = ToSelectObjectInner<UnwrapColumns<COLS>>;
// type ToSelectObjectInner<COLS> = {
//   [K in keyof COLS as COLS[K] extends [infer T, ...any] ? T extends string ? T : never : never]:
//   COLS[K] extends [infer T, ...infer R] ? R extends [] ? true : { select: ToSelectObjectInner<Filter<COLS, T>> } : never;
// }

// export type UnwrapColumns<COLS extends readonly (SPPI | { key: SPPI })[]> = {
//   [K in keyof COLS as K extends `${infer N extends number}` ? N : never]:
//   (COLS[K] extends { key: infer U } ? U : COLS[K]) extends { [ARRAY_PATH_SYMBOL]: infer U } ? U : never
// }

export type DataListResult<T extends TABLE_NAMES, COLS extends readonly (SPPI | { key: SPPI })[]> =
  GetResult<Prisma.TypeMap<DefaultArgs>["model"][T]["payload"], {
    select: ToSelectObject<COLS>
  }, 'findMany'>;


{

  // const customerRentalViewColumns = (x: SelectTypeTree<"Rental">) => [
  //   { key: x.unit.id.__, hidden: true },
  //   x.StartDate.__,
  //   x.EndDate.__,
  //   x.RentalStatus.__,
  //   x.unit.Name.__,
  //   x.unit.unitType.Name.__,
  //   x.promotion.Title.__,
  //   x.IsRentToOwn.__,
  //   x.unit.unitType.RentalPrice.__,
  //   x.unit.currentBranchMarkup.Markup.__,
  //   x.PriceOverride.__,
  //   { key: x.promotion.id.__, hidden: true },
  //   { key: x.unit.currentBranch.id.__, hidden: true },
  //   { key: x.unit.currentOwner.id.__, hidden: true },
  // ] as const;


  // function check<X extends TYPE_NAMES, R extends readonly (SPPI | { key: SPPI })[]>(
  //   table: X, row: any, keys: R
  // ): row is X extends TABLE_NAMES ? DataListResult<X, R>[number] : unknown {
  //   return true;
  // }
  // const t = check("Rental", {}, customerRentalViewColumns({} as any));
  // const row = {} as any;
  // const x: SelectTypeTree<"Rental"> = {} as any;

  // if (!check("Rental", row, [
  //   x.PriceOverride.__,
  //   x.unit.unitType.RentalPrice.__,
  //   x.unit.currentBranchMarkup.Markup.__,
  // ] as const)) throw "";
  // const t = row;
  // type t2 = [SPPI<["PriceOverride"]>, SPPI<["unit", "unitType", "RentalPrice"]>, SPPI<["unit", "currentBranchMarkup", "Markup"]>];
  // type t1 = DataListResult<"Rental", t2>;
  // type COLS = UnwrapColumns<[SPPI<["id"]>, ...ReturnType<typeof customerRentalViewColumns>]>
  // type cols2 = {
  //   [K in keyof COLS]: COLS[K] extends { key: infer U } ? U : COLS[K];
  // }
  // type filter2<COLS> = {
  //   [K in keyof Filter<COLS,T> as Filter<COLS,T>[K] extends [infer T, ...any] ? T extends string ? T : never : never]:
  //   Filter<COLS,T>[K] extends [infer T extends string, ...infer R] ? R extends [] ? true : { select: ToSelectObject<Filter<Filter<COLS,T>, T>> } : never;
  // }
  // type filter1<COLS> = {
  //   [K in keyof COLS as COLS[K] extends [infer T, ...any] ? T extends string ? T : never : never]:
  //   COLS[K] extends [infer T, ...infer R] ? R extends [] ? true : { select: filter1<Filter<COLS, T>> } : never;
  // }
  // type c2 = ToSelectObject<COLS>
  // type c3 = c2["unit"]["select"]["unitType"]["select"]
  // type customerRentalViewResult = DataListResult<"Rental", COLS>;
}
{
  // const t = PrismaQuery.selectPathsProxy("Branch",);
  const t = (x: SelectTypeTree<"Branch">) => [
    x.DisplayName.__,
    x.BranchType.__,
    x.division.Name.__,
    x.division.billing.Address.__,
  ] as const;
}