import React from "react";
import { useAngular, useAsyncEffect, useEventEmitter } from "react-utils";
import { PaymentFormInput, PaymentFormOutput, PaymentLedgerKeys, ok } from "common";
import { DataService } from "data-service";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { of, share, shareReplay, switchMap } from "rxjs";
import { EventEmitter } from "@angular/core";
import { PaymentMode } from "./ProvidePaymentProcessor";

type Keys = "status" | "token-id" | "form-url" | "amount";
export type PaymentRequest = <M extends Keys>(key: M, input: PaymentFormInput[M], skipAlert?: boolean) => Promise<PaymentFormOutput[M]>;
export type PaymentApp = ReturnType<typeof usePaymentStatus> & {};

export function usePaymentStatus(PaymentLedger: PaymentLedgerKeys, hostID: string) {
  const { get } = useAngular();
  const data = get(DataService);

  ok(hostID);

  const [tokenUpdateStatus, setUpdateStatus] = useState({});
  /** Calls a setState to initiate status update */
  const updateStatus = useCallback(() => setUpdateStatus({}), []);

  const request: PaymentRequest = useCallback(async <M extends Keys>(key: M, input: PaymentFormInput[M], skipAlert?: boolean) => {
    return await data.server.serverPaymentsEndpoint({ key, body: input as any, otherID: hostID, PaymentLedger }, skipAlert) as any;
  }, [data, PaymentLedger, hostID]);

  const { result } = useAsyncEffect(async () => {
    await data.ready;
    let count = 0;
    const json = await request("status", {}, true).catch(e => {
      if (e === "We had to fix something. This shoud be rare. Please try again.") {
        if (count++ > 1) throw e;
        else return request("status", {}, true);
      } else {
        alert("Payment Info Error: " + e);
        throw e;
      }
    });
    if (!json) return;
    if (json.ccExpiry?.length === 4) json.ccExpiry = `${json.ccExpiry.slice(0, 2)}/${json.ccExpiry.slice(2, 4)}`;
    return {
      status: json,
      hasInfo: json.valid,
      isCard: json.flags[0] === "c",
    };
  }, undefined, undefined, [tokenUpdateStatus]);

  const onSaveEvent = useEventEmitter<object>();

  const getFormUrl = useCallback(async () => (await request("form-url", { redirect: location.href })).formUrl, [request]);
  const formUrl = useMemo(() => of(true).pipe(switchMap(e => getFormUrl()), shareReplay(1)), [getFormUrl]);

  return useMemo(() => {
    if (!result) return undefined;
    const { status, hasInfo, isCard } = result;
    return {
      /** Value is memo-ized on PaymentLedger and hostID */ status,
      /** Value is memo-ized on PaymentLedger and hostID */ hasInfo,
      /** Value is memo-ized on PaymentLedger and hostID */ isCard,
      /** Value is memo-ized on PaymentLedger and hostID */ request,
      /** Value is memo-ized on PaymentLedger and hostID */ updateStatus,
      /** Value is memo-ized on PaymentLedger and hostID */ formUrl,
      /** Value is memo-ized on PaymentLedger and hostID */ onSaveEvent,
    };
  }, [result, request, updateStatus, formUrl, onSaveEvent,]);

}

export function useFormUrl(getFormUrl: () => Promise<{ formUrl: string; }>) {
  const [formUrl, setFormUrl] = useState("");
  const [formUrlLoading, setFormUrlLoading] = useState(false);

  useAsyncEffect(async () => {
    if (formUrl || formUrlLoading) return;
    setFormUrlLoading(true);
    const res = await getFormUrl();
    setFormUrlLoading(false);
    if (res.formUrl) setFormUrl(formUrl);
  }, undefined, undefined, [formUrl, formUrlLoading]);

  return { formUrl, formUrlLoading };

}

export interface PaymentModelProps {
  app: PaymentApp;
  events: EventEmitter<PaymentMode>;
}


