import {
  BlockStack,
  Box,
  Card, IndexFilters,
  IndexFiltersProps, Page, TabProps, TextField, useSetIndexFiltersMode, Spinner
} from "@shopify/polaris";
import { UIService, truthy, schema, is, TABLE_NAMES, SPPI, DataQueryGraph, Generator, Modes, TableViewColumn, ObjectPathTree, FieldClass, String } from "common";
import * as PrismaExtra from "prisma-client";
import { DataService } from "data-service";
import { useCallback, useMemo, useState } from "react";
import { useAngular, useAsyncEffect, useObservable } from "react-utils";
import { QuestionAutoComplete } from "../components/QuestionAutoComplete";
import { DatePickerComboBox } from "../components/DatePicker";

import { SelectDropdown } from "../components/Select";
import { QuestionInputNumberComp } from "../components/QuestionInputNumber";
import { TableView, TableViewClass } from "../tables/table-views";
import { TableListSimple } from "../tables/TableListInner";
import { format } from "date-fns";
import { QuestionTableComp } from "../components/QuestionTableComp";
import { QuestionGroup, QuestionSubGroup, QuestionTable, useDataPage } from "../utils";
import { renderQuestionItem } from "../components/QuestionForm";
import { useNavigate } from "react-router";
import Stripe from "stripe";
import { SelectTable, useTableListTree } from "../tables/SelectTable";
import { CustomColumnState } from "../tables/CustomColumnState";
import { fetchAuthSession } from "@aws-amplify/auth";
import { Location } from "@aws-sdk/client-location";


function SalesTaxRates({ config }: { config: "header_in_card" | "header_above_card" | "no_header" | "no_card" | "no_header_or_card" }) {
  const { get } = useAngular();
  const ui = get(UIService);
  const mode = "UPDATE" as const;
  const group = useCallback((mode: Modes) => Generator.Group("Branch", mode, ui, { billing: { TaxRates: true } }) as
    QuestionGroup<"Branch", { billing: QuestionSubGroup<{ TaxRates: QuestionTable<any>; }>; }>, [ui]);
  const adapt = useDataPage("Branch", mode, "", group);
  if (!adapt.group) return null;
  adapt.group.controls.billing.group.controls.TaxRates.config = config;
  return (
    <Card padding="400">
      {renderQuestionItem(adapt.group.controls.billing.group.controls.TaxRates, mode, true)}
    </Card>
  );
}

function RenderInner({ table, view, rows: rows2 }: { table: TABLE_NAMES | undefined, view: TableView, rows: any[] }) {
  const navigate = useNavigate();
  const data = useAngular().get(DataService);
  const { cols, rows, idcol, value, setValue } = useTableListTree(table, view);
  const customs = useMemo(() => table ? new CustomColumnState(table, data, cols) : undefined, [table, cols]);
  if (rows2 !== rows) setValue({ action: "reset", newValue: rows2 });
  const onSelectRow = useCallback((row: any) => {
    navigate(`/${row.metadata.PaymentLedger}/edit/${row.metadata.HostID}`);
  }, [navigate]);
  return <SelectTable {...{
    cols, rows, idcol, value, setValue, customs, onClickRow: onSelectRow
  }} />;
}
let fieldindex = 0;
class StringClass extends FieldClass { constructor(key: string) { super(key, fieldindex++, new String(), false, false); } }



const STRIPE_LIMIT = 10;
function useStripeData(data: DataService) {

  const [options, setOptions] = useState<Stripe.AccountListParams>({ limit: STRIPE_LIMIT });
  // const [rows, setRows] = useState<Stripe.Account[]>([]);
  const [offset, setOffset] = useState(0);

  const view = useMemo(() => TableViewClass.makeClientView(undefined, {
    AND: [],
    key: "stripeview",
    title: "Stripe Accounts List",
    list: (x: ObjectPathTree<Stripe.Account & { metadata: { PaymentLedger: string, Name: string } }, []>) => [
      new TableViewColumn({
        key: x.requirements.eventually_due.__,
        title: "Fields Due",
        aggregate: "array",
        markup: (v: string[] | null | undefined) => {
          return (
            <BlockStack gap="0">
              {v?.map(e => <span>{e}</span>)}
            </BlockStack>
            // <Tooltip content={v ? v.join(", ") : ""}>
            //   <Badge>{v ? v.length.toString() : ""}</Badge>
            // </Tooltip>
          );
        },
        custom: new StringClass("FieldsDue"),
      }),
      new TableViewColumn({
        key: x.metadata.Name.__,
        title: "Name",
        custom: new StringClass("Name"),
      }),
      new TableViewColumn({
        key: x.metadata.PaymentLedger.__,
        title: "Payment Ledger",
        custom: new StringClass("PaymentLedger"),
      }),
    ],
  }), []);

  const { loading, result: rows } = useAsyncEffect(async () => {
    const res = await data.server.serverStripeAccountList(options);
    while (res.has_more) {
      const newrows = await data.server.serverStripeAccountList({ ...options, starting_after: res.data[res.data.length - 1].id });
      res.data.push(...newrows.data);
      res.has_more = newrows.has_more;
    }
    return res.data;
  }, undefined, undefined, [options]);

  return { loading, rows, view };
}

export function AppTesting() {
  const { get } = useAngular();
  const data = get(DataService);
  const { loading, rows, view } = useStripeData(data);

  return <Page>
    <BlockStack gap="400">
      <Card>
        {!loading && rows && <RenderInner table={undefined} view={view} rows={rows} />}
        {loading && <Spinner />}
      </Card>
      {/* <Layout>
        <Layout.Section variant="oneThird">

        </Layout.Section>
        <Layout.Section>
          <BlockStack gap="400">
            <SalesTaxRates config="header_above_card" />
            <SalesTaxRates config="header_in_card" />
            <SalesTaxRates config="no_card" />
            <SalesTaxRates config="no_header" />
            <SalesTaxRates config="no_header_or_card" />
          </BlockStack>
        </Layout.Section>
      </Layout> */}
    </BlockStack>
  </Page >

}


function payoutBalanceWhereLine() {
  return ({
    VoidSince: null,
    IS_TESTING: false,
    OR: [
      // invoice lines include the other accounts where relevent amounts are recorded
      // invoice lines that haven't arrived yet do not get paid out, even if they're paid
      { invoiceLine: { paidOn: { not: null }, }, Date: { lte: format(Date.now(), "yyyy-MM-dd") } },
      // these would be payout lines, not customer payments, 
      // since customer payments are only charged to the customer ledger, 
      // not to the payout ledgers
      { paymentLine: { PaymentStatus: "Cleared" } },
      { paymentLine: { PaymentStatus: "Approved" } },
      // no idea what these are. Probably adjustments. They should be included regardless.
      { paymentLine: null, invoiceLine: null, }
    ]
  }) satisfies PrismaExtra.Prisma.TransactionWhereInput;
}



export function CentralHoldingLine() {
  const { get, injector } = useAngular();
  const ui = get(UIService);
  const data = get(DataService);


  const table = useMemo(() => ui.QuestionHasMany("CentralHoldingLine", undefined, undefined, {
    hidden: true,
    onlyfor: [],
    clientSideOnly: true,
    arrayList: ["Name", "Amount"] as SPPI[],
    subform: "Ledger",
    title: "Central Ledger Balance",
    preventUpdate: true,
    preventCreate: true,
    preventDelete: true,
  }), [ui]);

  useAsyncEffect(async function () {
    const query = new DataQueryGraph("CentralHoldingLine", "", "web_admin");
    const self = {
      data: data,
      payoutBalanceWhereLine: payoutBalanceWhereLine,
    }
    const prom = Promise.all([
      query.addPromise(self.data.proxy.invoiceLine.aggregate({
        _sum: { BranchPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: { Date: { lte: format(Date.now(), "yyyy-MM-dd") } },
        }
      })),
      query.addPromise(self.data.proxy.salesTaxLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: {
            Date: { lte: format(Date.now(), "yyyy-MM-dd") },
            invoiceLine: { paidOn: { not: null } }
          },
        }
      })),
      query.addPromise(self.data.proxy.branchLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: self.payoutBalanceWhereLine(),
        }
      })),
      query.addPromise(self.data.proxy.invoiceLine.aggregate({
        _sum: { OwnerPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: { Date: { lte: format(Date.now(), "yyyy-MM-dd") } },
        }
      })),
      query.addPromise(self.data.proxy.ownerLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: self.payoutBalanceWhereLine(),
        },
      })),
      query.addPromise(self.data.proxy.divisionLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: self.payoutBalanceWhereLine(),
        },
      })),
      query.addPromise(self.data.proxy.centralLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: self.payoutBalanceWhereLine(),
        },
      })),
      query.addPromise(self.data.proxy.paymentLine.aggregate({
        _sum: { PaymentFee: true },
        where: {
          line: self.payoutBalanceWhereLine(),
          PaymentStatus: { in: ["Cleared", "Approved"] },
        }
      }))
    ]);
    await data.dataGraphQuery(query, "requests");
    const [BranchPaymentFee, SalesTax, BranchLedger, OwnerPaymentFee, OwnerLedger, DivisionLedger, CentralLedger, CustomerPaymentFee] = await prom;
    table.setState({
      action: "reset",
      newValue: [
        { Name: "Branch Balance", Amount: (BranchLedger._sum.Amount ?? 0) - (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
        { Name: "- Branch Ledger", Amount: BranchLedger._sum.Amount },
        { Name: "- Branch Payment Fee", Amount: -(BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
        { Name: "Sales Tax", Amount: SalesTax._sum.Amount },
        { Name: "Owner Balance", Amount: (OwnerLedger._sum.Amount ?? 0) - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
        { Name: "- Owner Ledger", Amount: OwnerLedger._sum.Amount ?? 0 },
        { Name: "- Owner Payment Fee", Amount: -(OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
        { Name: "Division Ledger", Amount: DivisionLedger._sum.Amount },
        { Name: "Central Ledger", Amount: CentralLedger._sum.Amount },
        { Name: "Customer Payment Fee", Amount: CustomerPaymentFee._sum.PaymentFee },
        {
          Name: "Total", Amount: 0
            + (BranchLedger._sum.Amount ?? 0)
            + (SalesTax._sum.Amount ?? 0)
            + (OwnerLedger._sum.Amount ?? 0)
            + (DivisionLedger._sum.Amount ?? 0)
            - (CustomerPaymentFee._sum.PaymentFee ?? 0)
        }
      ]
    })
  });
  useObservable(table.form.valueChanges);
  return (
    <Page fullWidth>
      <BlockStack gap="300">
        {table.rows.length > 0 && <QuestionTableComp q={table} mode="UPDATE" />}
      </BlockStack>
    </Page>
  );

}

function AnyTable() {
  const [table, setTable] = useState<string | null>("Customer");
  const [list, setList] = useState<any[]>(["id"]);
  const [rawlist, setRawList] = useState<string>(list.join("\n"));
  const [sort, setSort] = useState<any[]>([]);
  // const [error, setError] = useState(false);
  console.log(table, schema);

  if (!is<TABLE_NAMES>(table, !!table && !!schema.tables[table])) { debugger; return null; }


  let valid: boolean;
  try {
    const testview = useMemo(() => new TableViewClass(table, list, sort), [table, list, sort]);
    testview.makeDataListColumns();
    valid = true;
  } catch (e) {
    valid = false;
  }

  const view = useMemo((): TableView => ({
    list: () => list,
    sort: () => sort,
    AND: [],
    key: "simpleView",
    title: "Simple View",
  }), [list, sort]);

  return (
    <Card padding="0">
      {/* <IndexTableWithViewsSearchFilterSorting /> */}
      <Box padding="300">
        <SelectDropdown
          label="Select Table"
          labelHidden
          options={Object.keys(schema.tables).map(v => ({ label: v, value: v }))}
          value={table}
          onChange={setTable}
        />
      </Box>
      <Box padding="300">
        <TextField
          label="List"
          labelHidden
          value={rawlist}
          onChange={setRawList}
          onBlur={() => { setList(rawlist.split("\n")); }}
          autoComplete="off"
          multiline
          error={!valid && "Invalid list"}
        />
      </Box>
      {valid && <TableListSimple table={table} view={view} />};
    </Card>
  )

}

function CompTest() {
  const [valueAddress, setValueAddress] = useState<{ Text: string | undefined } | null>(null);
  const [valueCalendar, setValueCalendar] = useState<string | null>("");
  const [valueCurrency, setValueCurrency] = useState<number | null>(2500);
  const [valueDecimal, setValueDecimal] = useState<number | null>(2500);

  return <Card>
    <BlockStack gap="300">
      <DatePickerComboBox valueType="string" value={valueCalendar} onChange={setValueCalendar} label="Date picker" autoComplete="off" />
      <QuestionAutoComplete
        value={valueAddress}
        onChange={setValueAddress}
        label="Auto complete"
        onSearch={onSearch}
        autoComplete="off"
        optionLabel="Text"
        optionValue={"Text"}
        searchOnFocus
      />
      {[undefined, "Select an option..."].map(placeholder => {
        const [valueSelect1, setValueSelect1] = useState<string | null>("");
        return <>
          <SelectDropdown
            label="Select"
            options={[
              { label: "Option 1", value: "1" },
              { label: "Option 2", value: "2" },
              { label: "Option 3", value: "3" },
            ]}
            value={valueSelect1 ?? undefined}
            placeholder={placeholder}
            onChange={(v: any) => { setValueSelect1(v) }}
          />
          <SelectDropdown
            label="Select"
            options={[]}
            value=""
            placeholder={placeholder}
            onChange={(v: any) => console.log(v)}
          />
        </>;
      })}
      <QuestionInputNumberComp
        inputMode="currency"
        value={valueCurrency}
        onChange={setValueCurrency}
        mode="UPDATE"
        label="Currency"
        id="currency"
        required={false}
        setErrors={() => { }}
        autoComplete="off"
        disabled={false}
        helpText={undefined}
        readOnly={false}
      />
      <QuestionInputNumberComp
        inputMode="decimal"
        value={valueDecimal}
        onChange={setValueDecimal}
        mode="UPDATE"
        label="Decimal"
        id="decimal"
        required={false}
        setErrors={() => { }}
        autoComplete="off"
        disabled={false}
        helpText={undefined}
        readOnly={false}
      />
    </BlockStack>
  </Card>
}

const onSearch = async (input: string) => {
  const { credentials } = await fetchAuthSession();
  const res = await new Location({
    region: "us-east-2",
    credentials,
  }).searchPlaceIndexForSuggestions({
    IndexName: "CustomerLocationLookup",
    FilterCountries: ["USA"],
    Text: input,
  });
  return res.Results?.filter(truthy) ?? [];
}



function IndexTableWithViewsSearchFilterSorting() {
  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    'All',
    'Unpaid',
    'Open',
    'Closed',
    'Local delivery',
    'Local pickup',
  ]);
  const deleteView = (index: number) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name: string) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs: TabProps[] = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => { },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
          {
            type: 'rename',
            onAction: () => { },
            onPrimaryAction: async (value: string): Promise<boolean> => {
              const newItemsStrings = tabs.map((item, idx) => {
                if (idx === index) {
                  return value;
                }
                return item.content;
              });
              await sleep(1);
              setItemStrings(newItemsStrings);
              return true;
            },
          },
          {
            type: 'duplicate',
            onPrimaryAction: async (value: string): Promise<boolean> => {
              await sleep(1);
              duplicateView(value);
              return true;
            },
          },
          {
            type: 'edit',
          },
          {
            type: 'delete',
            onPrimaryAction: async () => {
              await sleep(1);
              deleteView(index);
              return true;
            },
          },
        ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value: string) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => { };

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction: IndexFiltersProps['primaryAction'] = {
    type: 'save',
    onAction: onHandleSave,
    disabled: false,
    loading: false,
  };


  return (
    <IndexFilters
      tabs={tabs}
      selected={selected}
      onSelect={setSelected}

      // sortOptions={sortOptions}
      // sortSelected={sortSelected}
      // onSort={setSortSelected}


      // queryValue={queryValue}
      // queryPlaceholder="Searching in all"
      onQueryChange={() => { }}
      onQueryClear={() => { }}

      primaryAction={primaryAction}
      cancelAction={{
        onAction: onHandleCancel,
        disabled: false,
        loading: false,
      }}

      canCreateNewView
      onCreateNewView={onCreateNewView}

      filters={[]}
      appliedFilters={[]}
      onClearAll={() => { }}

      mode={mode}
      setMode={setMode}

      hideQueryField
      showEditColumnsButton
      disableKeyboardShortcuts
      hideFilters

    />
  );

  function disambiguateLabel(key: string, value: string | any[]): string {
    switch (key) {
      case 'moneySpent':
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case 'taggedWith':
        return `Tagged with ${value}`;
      case 'accountStatus':
        return (value as string[]).map((val) => `Customer ${val}`).join(', ');
      default:
        return value as string;
    }
  }

  function isEmpty(value: string | string[]): boolean {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}