import { ColumnBase, DataListColumn, DataListLookupColumn, IDataListColumn } from '../utils';
import { AnyMember, Boolean, CubesDinero, DataQueryGraph, dateCubes, DateExt, field, FieldClass, Float, is, ok, Prisma, PrismaQuery, ScalarDate, String, TABLE_NAMES, TableViewColumn, truthy } from "common";
import * as PrismaExtra from "prisma-client";
import { DataService } from "data-service";
import { format } from "date-fns";
import { TableColumnViewOptions, TableViewClass, assignColumnOptions } from './table-views';
import { SRMap } from "common";
let fieldindex = 0;

export class MemberClass extends FieldClass { constructor(key: string, item: AnyMember<any, any>) { super(key, fieldindex++, item, false, false); } }

export class CustomColumnDef<V> {
  static getColumnClass(key: string, filterType: "boolean" | "currency" | "text" | "numeric" | "date" | "enum" | "none") {

    const filterType2 =
      filterType === "currency" ? "numeric" :
        filterType === "none" ? undefined :
          filterType;

    const fieldAttr = new field({ filterType: filterType2 });
    switch (filterType) {
      case "boolean": return new MemberClass(key, new Boolean(false, fieldAttr));
      case "currency": return new MemberClass(key, new CubesDinero(false, fieldAttr));
      case "text": return new MemberClass(key, new String(false, fieldAttr));
      case "numeric": return new MemberClass(key, new Float(false, fieldAttr));
      case "date": return new MemberClass(key, new ScalarDate("yyyy-MM-dd", false, fieldAttr));
      case "enum": return new MemberClass(key, new String(false, fieldAttr));
      case "none": return new MemberClass(key, new String(false, fieldAttr));
      default: ok(false, "Invalid filterType: " + filterType);
    }

  }
  map: Map<string, V>;
  col: DataListLookupColumn<V>;
  constructor(
    key: string,
    title: string,
    filterType: "boolean" | "currency" | "text" | "numeric" | "date" | "enum" | "none",
    options: TableColumnViewOptions = {}
  ) {


    this.col = new DataListLookupColumn(key, 0, title, CustomColumnDef.getColumnClass(key, filterType));
    this.map = this.col.lookup;
    assignColumnOptions(this.col, options);
  }
}

export class CustomColumnState {
  constructor(
    private table: TABLE_NAMES,
    private data: DataService,
    private cols: ColumnBase[]
  ) {
    this.autopayColumns.forEach(([key, title, filterType, fn]) => {
      this[key] = new CustomColumnDef<any>(
        key,
        title,
        filterType,
        { displayGroup: "Autopay" },
      );
    });

    if (this.table === "Customer") {
      this.register(this.balanceLookup);
      this.register(this.unusedBalanceLookup);
      this.register(this.duedateLookup);
    }

    if (this.data.userRole === "web_admin" && ["Branch", "Owner", "Division"].contains(table)) {
      this.register(this.balanceLookup);
      if (table === "Branch") {
        this.register(this.salesTaxLookup);
      }
    }

    if (this.table === "CustomerLedger") {

      if (this.data.userRole === "web_admin") {
        this.autopayColumns.forEach(([key, title, filterType, fn]) => {
          this.register(this[key]);
        });
      } else {
        this.register(this.willAutopay);
      }
    }

  }

  register(col: CustomColumnDef<any>) {
    this.cols.push(col.col);
  }


  canceled: boolean;

  // promiseCustomerBalance?: Promise<void>;
  // promisePaymentDate?: Promise<void>;
  // promiseBranchBalance?: Promise<void>;
  // promiseOwnerBalance?: Promise<void>;
  // promiseDivisionBalance?: Promise<void>;

  onLoadHook(query: DataQueryGraph, AND: any[]) {
    query.addExtraRequest(() => {
      const proms = [];

      if (this.table === "Customer") {
        proms.push(this.clientCustomerBalance(AND));
        proms.push(this.clientCustomerPaidBalance(AND));
        proms.push(this.clientPaymentDate(AND));
      }

      if (this.data.userRole === "web_admin") {
        if (this.table === "Branch") {
          proms.push(this.clientBranchBalance(AND));
        } else if (this.table === "Owner") {
          proms.push(this.clientOwnerBalance(AND));
        } else if (this.table === "Division") {
          proms.push(this.clientDivisionBalance(AND));
        }
      }

      if (is<Prisma.CustomerLedgerWhereInput[]>(AND, this.table === "CustomerLedger")) {
        proms.push(this.clientAutopayLoad(AND));
      }

      return Promise.all(proms);

    });

  }

  onValueFilter(value: any[]): any[] {

    if (this.table === "Customer") {
      // the balance only includes non-testing lines, 
      // so test customers are confusing because they would show as zero.
      value.forEach(e => {
        if (e.id && e.IS_TESTING === true)
          this.balanceLookup.map.delete(e.id);
      });
    }

    return value;

  }

  balanceLookup = new CustomColumnDef<number>(
    "balanceLookup",
    "Account Balance",
    "currency",
    { displayGroup: "Balance" },
  );

  unusedBalanceLookup = new CustomColumnDef<number>(
    "paidBalanceLookup",
    "Unused Balance",
    "currency",
    { displayGroup: "Balance" },
  );

  salesTaxLookup = new CustomColumnDef<number>(
    "salesTaxLookup",
    "Sales Tax Balance",
    "currency",
    { displayGroup: "Balance" },
  );

  duedateLookup = new CustomColumnDef<string>(
    "duedateLookup",
    "Next Due Date",
    "date",
    { displayGroup: "Balance" },
  );

  autopayColumns = [
    ["willAutopay", "Will Autopay", "boolean", e => e.thisLinePaid ? undefined : e.willAutopay],
  ] as const satisfies [string, string, string, (e: CustomColumnState["autopayLine"]) => any][];
  willAutopay: CustomColumnDef<any>
  declare autopayLine: Awaited<ReturnType<SRMap["queryGetAutopayForCustomerLedger"]>>[number];
  async clientAutopayLoad(AND: Prisma.CustomerLedgerWhereInput[] = []) {
    const autopay = await this.data.server.queryGetAutopayForCustomerLedger({ AND });
    if (this.canceled) return;
    this.autopayColumns.forEach(([key, title, filterType, fn]) => {
      this[key].map.clear();
    });
    autopay.forEach(e => {
      this.autopayColumns.forEach(([key, title, filterType, fn]) => {
        this[key].map.set(e.id, fn(e));
      });
    });
  }

  async clientBranchBalance(AND: any[]) {
    const { branches, salesTax } = await this.data.server.queryBranchBalance({ AND });
    if (this.canceled) return;
    this.balanceLookup.map.clear();
    branches.forEach(([branchID, balance]) => this.balanceLookup.map.set(branchID, balance));
    this.salesTaxLookup.map.clear();
    salesTax.forEach(([branchID, balance]) => this.salesTaxLookup.map.set(branchID, balance));
  }

  async clientOwnerBalance(AND: any[]) {
    const mapBalance = await this.data.server.queryOwnerBalance({ AND });
    if (this.canceled) return;
    this.balanceLookup.map.clear();
    mapBalance.forEach(([ownerID, balance]) => this.balanceLookup.map.set(ownerID, balance));
  }
  async clientDivisionBalance(AND: any[]) {
    const mapBalance = await this.data.server.queryDivisionBalance({ AND });
    if (this.canceled) return;
    this.balanceLookup.map.clear();
    mapBalance.forEach(([divisionID, balance]) => this.balanceLookup.map.set(divisionID, balance));
  }
  payoutBalanceWhereLine() {
    return ({
      VoidSince: null,
      IS_TESTING: false,
      OR: [
        // invoice lines include the other accounts where relevent amounts are recorded
        // invoice lines that haven't arrived yet do not get paid out, even if they're paid
        { invoiceLine: { paidOn: { not: null }, }, Date: { lte: format(Date.now(), "yyyy-MM-dd") } },
        // these would be payout lines, not customer payments, 
        // since customer payments are only charged to the customer ledger, 
        // not to the payout ledgers
        { paymentLine: { PaymentStatus: "Cleared" } },
        { paymentLine: { PaymentStatus: "Approved" } },
        // no idea what these are. Probably adjustments. They should be included regardless.
        { paymentLine: null, invoiceLine: null, }
      ]
    }) satisfies PrismaExtra.Prisma.TransactionWhereInput;
  }

  async clientCustomerBalance(AND: Prisma.CustomerWhereInput[]) {
    const res = await this.data.server.queryCustomerBalance({ AND });
    if (this.canceled) return;
    this.balanceLookup.map.clear();
    res.forEach(e => this.balanceLookup.map.set(e.customerID, e._sum.Amount ?? 0));
  }

  async clientCustomerPaidBalance(AND: Prisma.CustomerWhereInput[]) {
    const res = await this.data.server.queryCustomerUnreconciledBalance({ AND });
    if (this.canceled) return;
    this.unusedBalanceLookup.map.clear();
    res.forEach(e => this.unusedBalanceLookup.map.set(e.customerID, (e._sum.Amount ?? 0)));
  }

  async clientPaymentDate(AND: Prisma.CustomerWhereInput[]) {
    const res = await this.data.server.queryPaymentDate({ AND });
    if (this.canceled) return;
    this.duedateLookup.map.clear();
    res.forEach(cust => { this.duedateLookup.map.set(cust.id, cust.LedgerLines[0]?.line.Date); });
  }

}
