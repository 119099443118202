import { Card, MenuGroupDescriptor, Page } from '@shopify/polaris';
import { BaseLedger, Prisma, RecordMember, Root, SPPI, SPPTypeTree, StringPathProxy, TableViewColumn, UIService } from "common";
import { DataService } from "data-service";
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { useAngular, useLoadingMarkup } from "react-utils";
import { TableListInner } from "../tables/TableListInner";
import { PrismaWhereQuery, TableView, } from '../tables/table-views';
import { FormsQuestionService, useBranchSelector } from '../utils';



export function BranchLedgerPage() {

  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);

  const actionGroups: MenuGroupDescriptor[] = [];

  const { curBranch, branchSelectorActionGroup } = useBranchSelector();

  if (data.status.branchType === "CENTRAL" && branchSelectorActionGroup)
    actionGroups.push(branchSelectorActionGroup);

  const table = "Branch";

  const title = "Branch Bank Ledger (not A/R)";

  const loadingMarkup = useLoadingMarkup(pluralize(table).toLocaleLowerCase());

  const views = useMemo(() => ledgerTableViews<Prisma.BranchLedgerWhereInput>([{ branchID: curBranch || undefined }], true), [curBranch]);

  const hiddenColumns = StringPathProxy<"BranchLedger">()(x => [x.branch.DisplayName.__,]);

  const onSelectRow = (id: string | undefined) => { if (id) fq.onClickLedgerLine("branch", id, false); }

  return <Page
    fullWidth
    title={title}
    actionGroups={actionGroups}
  >
    <Card>
      <p>customer = branch + sales tax + owner + division + central</p>
      <p>branch payout = branch - branch fee</p>
      <p>sales tax is being held separately</p>
      <p>Negative branch amounts are a payout</p>
      <p>On this page, ignore negative customer amounts.</p>
    </Card>
    <Card padding="0">
      <TableListInner {...{ table: "BranchLedger", views, loadingMarkup, hiddenColumns, onSelectRow }} />
    </Card>
  </Page>;
}


export const ledgerTableColumns = ({
  list: x => [
    x.line.Date.__,
    x.line.invoiceLine.item.ItemName.__,
    x.line.invoiceLine.rental.unit.Name.__,
    // x.Amount.__,
    x.line.invoiceLine.paidOn.__,
    x.line.paymentLine.PaymentStatus.__,
    // { key: x.line.paymentLine.PaymentFee.__, title: "Fee" },
    // x.line.VoidSince.__,
    // { key: x.line.branchDiscountLedgerLine.Amount.__, title: "Promotion", },
    // { key: x.line.invoiceLine.id.__, hidden: true, filterType: "none" },
    // { key: x.line.invoiceLine.rental.id.__, hidden: true, filterType: "none" },
    // { key: x.line.paymentLine.id.__, hidden: true, filterType: "none" },
    { key: x.line.customerLedgerLine.Amount.__, title: "Customer (inc Tax)" },
    { key: x.line.branchLedgerLine.Amount.__, title: "Branch" },
    { key: x.line.salesTaxLedgerLine.Amount.__, title: "Sales Tax", aggregate: "sum", filterType: "none" },
    { key: x.line.ownerLedgerLine.Amount.__, title: "Owner" },
    { key: x.line.divisionLedgerLine.Amount.__, title: "Division" },
    { key: x.line.centralLedgerLine.Amount.__, title: "Central" },
    { key: x.line.invoiceLine.BranchPaymentFee.__, title: "Branch Fee" },
    { key: x.line.invoiceLine.OwnerPaymentFee.__, title: "Owner Fee" },
    // x.line.customerLedgerLine.customer.AutoPay.__,
  ],
  sort: x => [
    `-${x.line.Date.__}` as SPPI,
  ],
} satisfies {
  list: columndef<Root["types"]["CentralLedger"]>,
  sort: columndef<Root["types"]["CentralLedger"]>,
});

type columndef<B extends RecordMember<any>> = (e: SPPTypeTree<B>) => (SPPI | TableViewColumn)[];

export function ledgerTableViews<W extends PrismaWhereQuery = PrismaWhereQuery>(AND: W[], filter_IS_TESTING: boolean): LedgerTableViews {

  const columns = ledgerTableColumns;

  return [
    {
      key: "valid",
      title: "All Valid",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            VoidSince: null,
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: false } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        }
      ]
    },
    {
      key: "pending",
      title: "In Progress",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            VoidSince: null,
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: false } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        },
        {
          line: {
            OR: [
              { paymentLine: { PaymentStatus: { not: "Cleared" } } },
              { invoiceLine: { paidOn: null } },
              { paymentLine: null, invoiceLine: null },
            ]
          }
        }
      ]
    },
    {
      key: "approved",
      title: "Approved Payments",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            VoidSince: null,
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: false } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        },
        {
          line: {
            OR: [
              { paymentLine: { PaymentStatus: "Approved" } },
            ]
          }
        }
      ]
    },
    {
      key: "voided",
      title: "Voided",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            VoidSince: { not: null },
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: false } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        }
      ]
    },
    ...filter_IS_TESTING ? [{
      key: "test",
      title: "Test Customers",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: true } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        }
      ]
    }] : []
  ] satisfies LedgerTableViews as LedgerTableViews;
}
// central is pretty generic
type LedgerTableViews<W = Prisma.CentralLedgerWhereInput> = readonly TableView<any[], "CentralLedger", W & PrismaWhereQuery, symbol>[];

