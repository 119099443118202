
import {
  ENUM_NAMES,
  GenericPathProxy,
  is,
  ok,
  okNull,
  Prisma, 
  PrismaQuery, root,
  schema,
  SelectTypeTree,
  SPPI, TABLE_NAMES,
  TableViewColumn,
  TYPE_NAMES
} from "common";
import * as PrismaExtra from "prisma-client";
import { DataListColumn, DataListEnumColumn, DataListGroupColumn, LedgerTables, NoBranchSelected } from "../utils";

export interface TableViewColumnNormal extends TableViewColumn {
  childgroup?: TableViewColumnNormal[];
}

export type ListValue = SPPI | TableViewColumn;
export interface TableView<
  L extends Record<string, ListValue> | readonly ListValue[] = readonly any[],
  B extends TYPE_NAMES = TYPE_NAMES,
  A extends PrismaWhereQuery = PrismaWhereQuery,
  C extends symbol = symbol
> extends TableArrays<L, B>, ViewOptions<A, C> { }

export interface TableArrays<
  L extends Record<string, ListValue> | readonly ListValue[] = readonly any[],
  B extends TYPE_NAMES = TYPE_NAMES
> {

  list?: (e: TYPE_NAMES extends B ? any : SelectTypeTree<B>) => L,
  sort?: (e: TYPE_NAMES extends B ? any : SelectTypeTree<B>) => SPPI[],
}

interface ViewFilter {
  key: string;
  filter: TableViewFilter;
}

export interface ViewOptions<
  A extends PrismaWhereQuery = PrismaWhereQuery,
  C extends symbol = symbol
> {
  /** This is the key of the view, not an array path */
  key: string,
  title: string,
  helptext?: string,
  AND: A[] | C,
  getCount?: boolean,
  hidden?: boolean,
  url?: string,
}

const decode = <B extends TYPE_NAMES, R extends Record<string, ListValue> | readonly ListValue[]>(
  type: B | undefined,
  a?: (e: string extends B ? any : SelectTypeTree<B>) => R
): R => (a && GenericPathProxy()(a) || []);

function normalizeList(item: ListValue): TableViewColumnNormal {
  if (typeof item === "string") { item = { key: item }; }
  if (item.childgroup) item.childgroup = item.childgroup.map(e => normalizeList(e)) ?? [];
  return item as any;
}

function getArrayList(item: TableViewColumnNormal): SPPI[] {
  return [item.key, ...item.childgroup?.flatMap(e => getArrayList(e)) ?? []];
}

export class TableViewClass<
  L extends Record<string, ListValue> | readonly ListValue[] = readonly any[],
  B extends TYPE_NAMES = any
> {

  static fromView<
    L extends Record<string, ListValue> | readonly ListValue[],
    B extends TYPE_NAMES
  >(type: B | undefined, view?: TableArrays<L, B>) {
    return new TableViewClass<L, B>(type, decode(type, view?.list), decode(type, view?.sort));
  }

  static fromClientView(type: TYPE_NAMES, view?: TableArrays<ListValue[]>) {
    return new TableViewClass(type, decode(type, view?.list), decode(type, view?.sort));
  }

  static makeClientView<
    L extends Record<string, ListValue> | readonly ListValue[] = readonly any[],
    B extends TYPE_NAMES = TYPE_NAMES,
    A extends PrismaWhereQuery = PrismaWhereQuery,
    C extends symbol = symbol
  >(type: B | undefined, view: TableView<L, B, A, C>): TableView<L, B, A, C> { return view; }


  private innerMap: Map<unknown, TableViewColumnNormal>;

  private keyMap: Map<string, TableViewColumnNormal>;

  getKey(key: string) { return this.keyMap.get(key); }
  hasKey(key: string) { return this.keyMap.has(key); }

  readonly isArray: boolean;
  readonly arrayList: readonly SPPI[];
  readonly arraySort: readonly SPPI[];

  constructor(private type: B | undefined, list: L, sort: readonly SPPI[], defaultSort = true) {

    this.isArray = Array.isArray(list);

    this.innerMap = new Map(([
      ...Array.isArray(list)
        ? list.entries()
        : Object.entries(list)
    ] as [unknown, ListValue][])
      .map(([k, v]) => [k, normalizeList(v)] as const));

    this.keyMap = new Map([...this.innerMap.values()].map((v) => [v.key, v] as const));

    this.arrayList = [...this.innerMap.values()].flatMap(e => getArrayList(e));

    if (sort.length === 0) {
      this.arraySort = defaultSort ? [this.arrayList[0]] : [];
    } else {
      this.arraySort = sort.slice();
    }
  }

  makeDataListColumns(): L extends readonly any[] ? DataListColumn[] : { [K in keyof L]: DataListColumn } {
    if (this.isArray) {
      return [...this.makeDataListColumnsInner().values()] as any;
    } else {
      const cols = this.makeDataListColumnsInner();
      const res: any = {};
      for (const [key, col] of cols.entries()) {
        if (typeof key !== "string") debugger;
        res[key as string] = col;
      }
      return res;
    }
  }

  private makeDataListColumnsInner() {
    const { arraySort: sort = [] } = this;
    const type = this.type as TYPE_NAMES;

    const sortarg = sort.reduce((n, e, i) => {
      const dec = e[0] === "-";
      n[e.slice(+dec)] = (i + 1) * (dec ? -1 : 1);
      return n;
    }, {} as any);
    const cols = new Map<unknown, DataListColumn>();
    for (const [key, e] of this.innerMap.entries()) {
      const skey = e.key;
      if (!e.custom) ok(type, "type is required unless all columns are custom");
      const field = e.custom || DataListColumn.getFieldType(skey, schema.typepath(type, skey));
      const args = [skey, e.sort || sortarg[skey], field] as const;
      const isEnum = field.type === "enum";

      let col: DataListColumn;

      if (e.childgroup) {
        const _col = new DataListGroupColumn(...args);
        _col.childgroup = e.childgroup;
        col = _col;
      } else if (is<{ type: { enum: ENUM_NAMES; }; }>(field, isEnum)) {
        col = new DataListEnumColumn(...args);
      } else {
        col = new DataListColumn(...args);
      }

      if (!e.custom) col.setSchema(type, schema.typepath(type, skey));

      cols.set(key, col);
      assignColumnOptions(col, e);

    }
    return cols;
  }


  getSelectObject() {
    ok(this.type, "type is required");
    return PrismaQuery.getQueryTree(["id" as any, ...this.arrayList], root.types[this.type], false);
  }

}

export type TableColumnViewOptions = Partial<Pick<TableViewColumnNormal,
  "hidden" | "title" | "sorter" | "aggregate" | "filterType" | "queryColumn" | "calculate" | "markup" | "displayGroup" | "link"
>>;

export function assignColumnOptions(col: DataListColumn, e: TableColumnViewOptions) {
  if (e.hidden) col.hidden = e.hidden;
  if (e.title) col.title = e.title;
  if (e.sorter) col.sorter = e.sorter;
  if (e.aggregate) col.aggregate = e.aggregate;
  if (e.filterType) col.filterType = e.filterType;
  if (e.queryColumn) col.queryColumn = e.queryColumn;
  if (e.calculate) col.calculate = e.calculate;
  if (e.markup) col.markup = e.markup;
  if (e.displayGroup) col.displayGroup = e.displayGroup;
  if (e.link) col.link = e.link;
}

export class TableViewOptions<
  L extends Record<string, ListValue> | readonly ListValue[] = readonly any[],
  B extends TYPE_NAMES = any,
  A extends PrismaWhereQuery = PrismaWhereQuery,
  C extends symbol = symbol
> extends TableViewClass<L, B> {

  static fromViewWithOptions<
    L extends Record<string, ListValue> | readonly ListValue[],
    B extends TYPE_NAMES,
    A extends PrismaWhereQuery = PrismaWhereQuery,
    C extends symbol = symbol
  >(type: B, view: TableArrays<L, B>, options: ViewOptions<A, C>, defaultSort = true) {
    return new TableViewOptions<L, B>(type, decode(type, view.list), decode(type, view.sort), options, defaultSort);
  }

  static fromClientViewWithOptions(type: TYPE_NAMES, view: TableArrays<ListValue[]>, options: ViewOptions, defaultSort = true) {
    return new TableViewOptions(type, decode(type, view.list), decode(type, view.sort), options, defaultSort);
  }

  AND: A[] | C;
  key: string;
  title: string;
  helptext?: string;
  getCount?: boolean;
  hidden?: boolean;
  url?: string;


  constructor(type: B, list: L, arraySort: SPPI[], options: ViewOptions<A, C>, defaultSort = true) {
    super(type, list, arraySort, defaultSort);

    this.AND = options.AND;
    this.key = options.key;
    this.title = options.title;
    this.helptext = options.helptext;
    this.getCount = options.getCount;
    this.hidden = options.hidden;
    this.url = options.url;

    okNull(this.key);
    okNull(this.title);
    okNull(this.AND);
  }
}

export type MaybeTableViewArray<L extends Record<string, ListValue> | readonly ListValue[]> = MaybeArray<TableView<L, any, any, symbol>> | readonly TableView<L, any, any, symbol>[];



type ArrayProps<T> = { [K in (string | symbol) & keyof []]: Array<T>[K] };
export interface MaybeArray<T> extends ArrayProps<T> {
  [n: number]: T | undefined;
}
const PrismaWhereQuerySymbol: unique symbol = Symbol();
export interface PrismaWhereQuery {
  [n: number]: never;
  [K: string]: any;
  [PrismaWhereQuerySymbol]?: undefined;
}
const TableViewFilterSymbol: unique symbol = Symbol();
export interface TableViewFilter {
  [n: number]: never;
  [K: string]: any;
  [TableViewFilterSymbol]?: undefined;
}


const DataTables = [...[
  "Customer",
  "Item",
  "Promotion",
  "Unit",
  "Branch",
  "Owner",
] as const];



type columndef<B extends TYPE_NAMES> = (e: SelectTypeTree<B>) => (SPPI | TableViewColumn)[];

const DataTableColumns = {
  UnitType: {
    list: x => [
      x.Name.__,
      x.Description.__,
      x.RentalPrice.__,
    ],
    sort: x => [],
  },
  Promotion: {
    list: (x => [
      x.Title.__,
      x.PercentOffBranch.__,
      x.PercentOffCentral.__,
      x.BillingCycles.__,
    ]),
    sort: x => [],
  },
  Customer: {
    list: x => [
      { displayGroup: "Name", key: x.billing.Name.__, queryColumn: true, },
      { displayGroup: "Email", key: x.Email.__, queryColumn: true, },
      { displayGroup: "Billing", key: x.billing.Address.description.__, queryColumn: true, },
      { displayGroup: "Billing", key: x.billing.Phone.__, queryColumn: true, },
      { displayGroup: "Status", key: x.EmailVerified.__, title: "Email" },
      { displayGroup: "Status", key: x.StorageAgreementCompleted.__, title: "Signed" },
      { displayGroup: "Status", key: x.PaymentInfoValid.__, title: "Payment" },
      { displayGroup: "Status", key: x.AutoPay.__, title: "AutoPay" },
      { key: x.AllRentals.activeUnit.id.__, title: "Rentals", aggregate: "count-truthy", filterType: "numeric" },
    ],
    sort: x => [],
  },
  Item: {
    list: x => [
      x.ItemName.__,
      x.ItemType.__,
    ],
    sort: x => [],
  },
  Unit: {
    list: x => [
      x.Name.__,
      x.unitType.Name.__,
      x.currentRental.RentalStatus.__,
      x.currentRental.customer.billing.Name.__,
      x.currentOwner.billing.Name.__,
      x.currentBranch.DisplayName.__,
      x.currentBranch.division.Name.__,

    ],
    sort: x => [],
  },
  Branch: {
    list: (x => [
      x.DisplayName.__,
      x.BranchType.__,
      x.division.Name.__,
      x.PaymentInfoValid.__,
    ]),
    sort: x => [],
  },
  BranchUnitTypeMarkup: {
    list: x => [
      x.unitType.Name.__,
      x.branch.DisplayName.__,
    ],
    sort: x => [],
  },
  Owner: {
    list: x => [
      x.billing.Name.__,
      x.Email.__,
      x.billing.Phone.__,
      x.division.Name.__,
      x.PaymentInfoValid.__,
    ],
    sort: x => [

    ],
  },
  BranchUser: {
    list: (x => [
      x.user.email.__,
      x.branch.DisplayName.__,
      x.branch.division.Name.__,
    ]),
    sort: x => [],
  },
  Division: {
    list: (x => [
      x.Name.__,
      x.PaymentInfoValid.__,
    ]),
    sort: x => [],
  },
  NoticeTemplate: {
    list: (x => [
      x.NoticeType.__,
      x.EmailSubject.__,
    ]),
    sort: x => [
      x.NoticeType.__,
    ],
  },
  InvoiceLine: {
    list: (x => [
      x.rental.unit.Name.__,
      x.line.Date.__,
      x.rental.customer.billing.Name.__,
      x.line.customerLedgerLine.Amount.__,
      x.line.invoiceLine.paidOn.__,
      x.line.VoidSince.__,
      x.line.customerLedgerLine.customer.IS_TESTING.__,
    ]),
    sort: (x => [
      x.line.Date.__,
      x.rental.unit.Name.__,
    ]),
  },
  PaymentLine: {
    list: x => [
      x.line.Date.__,
      x.line.customerLedgerLine.customer.billing.Name.__,
      x.line.customerLedgerLine.Amount.__,
      x.line.paymentLine.PaymentFee.__,
      x.line.paymentLine.PaymentStatus.__,
      x.line.VoidSince.__,
      x.line.customerLedgerLine.customer.IS_TESTING.__,
    ],
    sort: x => [],
  },
  Transaction: {
    list: (x => [
      x.Date.__,
      x.customerLedgerLine.Amount.__,
      x.branchLedgerLine.Amount.__,
      x.ownerLedgerLine.Amount.__,
      x.divisionLedgerLine.Amount.__,
      x.centralLedgerLine.Amount.__,
      x.paymentLine.PaymentStatus.__,
      x.invoiceLine.paidOn.__,
    ]),
    sort: x => [],
  },
  Rental: {
    list: (x => [
      x.customer.billing.Name.__,
      x.customer.Email.__,
      x.customer.AutoPay.__,
      x.unit.Name.__,
      x.promotion.Title.__,
      x.RentalStatus.__,
      x.StartDate.__,
      x.EndDate.__,
    ]),
    sort: (x => [
      x.RentalStatus.__,
      x.unit.Name.__,
    ]),
  },
  User: {
    list: x => [
      x.name.__,
      x.email.__,
      x.Branches.branch.DisplayName.__,
    ],
    sort: x => []
  },
  Permission: {
    list: x => [
      x.name.__,
    ],
    sort: x => []
  },
  UserPermission: {
    list: x => [
      x.user.email.__,
      x.perm.name.__,
    ],
    sort: x => []
  },
  OwnerGroup: {
    list: x => [
      x.DisplayName.__,
      x.GroupType.__,
    ],
    sort: x => []
  },
  BranchGroup: {
    list: x => [
      x.DisplayName.__,
      x.GroupType.__,
    ],
    sort: x => []
  },
  CustomerGroup: {
    list: x => [
      x.DisplayName.__,
      x.GroupType.__,
    ],
    sort: x => []
  },


} satisfies {
  [K in TABLE_NAMES]?: {
    list: columndef<K>,
    sort: columndef<K>,
  }
}

export const customerTableViews = ({
  curBranch, showAutopay
}: {
  curBranch: string;
  showAutopay: boolean;
}) => [
  {
    key: "branch-customers",
    title: "Branch",
    ...DataTableColumns.Customer,
    helptext: "Customers who signed up with this branch or have rentals with this branch.",
    AND: curBranch ? [{
      IS_TESTING: false,
      OR: [
        { AllRentals: { some: { unit: { currentBranchID: curBranch }, RentalStatus: { not: "Archived" } } } },
        { AllRentals: { none: {} }, firstContactBranchID: curBranch },
      ]
    }] : NoBranchSelected,
  },
  {
    key: "unclaimed",
    title: "Unclaimed",
    ...DataTableColumns.Customer,
    helptext: "Customers without a first contact branch and no rentals.",
    AND: [{
      IS_TESTING: false,
      firstContactBranchID: null,
      AllRentals: { none: {} },
    }],
    getCount: true,
  },
  {
    key: "active",
    title: "All",
    helptext: "Exclude test customers",
    ...DataTableColumns.Customer,
    AND: [{ IS_TESTING: false }],
  },
  {
    key: "autopay",
    title: showAutopay ? "Autopay Manually" : "",
    helptext: "Customers marked for autopay.",
    ...DataTableColumns.Customer,
    AND: [{ IS_TESTING: false, AutoPay: true }],
    hidden: !showAutopay,
    sort: x => ["-duedateLookup"] as SPPI[]
  },
  {
    key: "test",
    title: "Test",
    ...DataTableColumns.Customer,
    helptext: "Test customers only show on this tab",
    AND: [{ IS_TESTING: true }]
  }
] satisfies CustomerTableViews as CustomerTableViews;

type CustomerTableViews = TableView<any[], "Customer", Prisma.CustomerWhereInput & PrismaWhereQuery, typeof NoBranchSelected>[]

export function rentalTableViews(extra: Prisma.RentalWhereInput = {}) {

  return [
    {
      key: "attention",
      title: "Attention",
      AND: [extra, { customer: { IS_TESTING: false }, RentalStatus: { "in": ["Reserved", "Scheduled", "Moving_Out", "Completed", "Retained"] } }],
      ...DataTableColumns.Rental,
      helptext: "",
      getCount: true,
    },
    {
      key: "normal",
      title: "Normal",
      AND: [extra, { customer: { IS_TESTING: false }, RentalStatus: { "in": ["Rented"] } }],
      ...DataTableColumns.Rental,
    },
    {
      key: "released",
      title: "Released",
      AND: [extra, { customer: { IS_TESTING: false }, RentalStatus: { "in": ["Released", "Archived"] } }],
      ...DataTableColumns.Rental,
    },
    {
      key: "test",
      title: "Test Customers",
      AND: [extra, { customer: { IS_TESTING: true } }],
      ...DataTableColumns.Rental,
    },
  ] satisfies RentalTableViews as RentalTableViews;
}

type RentalTableViews = TableView<any[], "Rental", Prisma.RentalWhereInput & PrismaWhereQuery, never>[]

function dataTableViews<K extends keyof typeof DataTableColumns>(
  table: K,
  AND: PrismaWhereQuery[] = [{}]
) {
  return [{
    key: "list",
    title: table,
    ...DataTableColumns[table],
    AND,
  }];
}

export const getTableViews = (table: TABLE_NAMES) => {
  if (LedgerTables.contains(table))
    return ledgerTableViews([], true);
  if (table === "Rental")
    return rentalTableViews();
  if (is<keyof typeof DataTableColumns>(table, table in DataTableColumns))
    return dataTableViews(table, [
      table === "User" ? { Branches: { some: { branch: { is: {} } } } } satisfies PrismaExtra.Prisma.UserWhereInput :
        table === "BranchUser" ? { branch: { is: {} } } satisfies PrismaExtra.Prisma.BranchUserWhereInput :
          {}
    ]);
  return undefined;
}
export const ledgerTableColumns = ({
  list: x => [
    x.line.Date.__,
    x.line.invoiceLine.item.ItemName.__,
    x.line.invoiceLine.rental.unit.Name.__,
    x.Amount.__,
    x.line.invoiceLine.paidOn.__,
    x.line.paymentLine.PaymentStatus.__,
    { key: x.line.paymentLine.PaymentFee.__, title: "Fee" },
    x.line.VoidSince.__,
    // { key: x.line.branchDiscountLedgerLine.Amount.__, title: "Promotion", },
    { key: x.line.invoiceLine.id.__, hidden: true, filterType: "none" },
    { key: x.line.invoiceLine.rental.id.__, hidden: true, filterType: "none" },
    { key: x.line.paymentLine.id.__, hidden: true, filterType: "none" },
    { key: x.line.customerLedgerLine.Amount.__, title: "Customer" },
    { key: x.line.salesTaxLedgerLine.Amount.__, title: "Sales Tax", aggregate: "sum", filterType: "none" },
    { key: x.line.customerLedgerLine.customer.id.__, hidden: true, filterType: "none" },
    // x.line.customerLedgerLine.customer.AutoPay.__,
  ],
  sort: x => [
    `-${x.line.Date.__}` as SPPI,
  ],
} satisfies {
  list: columndef<"CentralLedger">,
  sort: columndef<"CentralLedger">,
});



export function ledgerTableViews<W extends PrismaWhereQuery = PrismaWhereQuery>(AND: W[], filter_IS_TESTING: boolean): LedgerTableViews {

  const columns = ledgerTableColumns;

  return [
    {
      key: "valid",
      title: "All Valid",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            VoidSince: null,
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: false } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        }
      ]
    },
    {
      key: "pending",
      title: "In Progress",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            VoidSince: null,
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: false } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        },
        {
          line: {
            OR: [
              { paymentLine: { PaymentStatus: { not: "Cleared" } } },
              { invoiceLine: { paidOn: null } },
              { paymentLine: null, invoiceLine: null },
            ]
          }
        }
      ]
    },
    {
      key: "voided",
      title: "Voided",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            VoidSince: { not: null },
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: false } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        }
      ]
    },
    ...filter_IS_TESTING ? [{
      key: "test",
      title: "Test Customers",
      ...columns,
      AND: [
        ...AND,
        {
          line: {
            ...filter_IS_TESTING ? {
              OR: [
                { customerLedgerLine: { customer: { IS_TESTING: true } } },
                { customerLedgerLine: null },
              ]
            } : {},
          }
        }
      ]
    }] : []
  ] satisfies LedgerTableViews as LedgerTableViews;
}
// central is pretty generic
type LedgerTableViews<W = Prisma.CentralLedgerWhereInput> = readonly TableView<any[], "CentralLedger", W & PrismaWhereQuery, symbol>[];

