import { Injectable, Injector } from "@angular/core";
import { getCurrentUser, fetchAuthSession, signInWithRedirect, signOut } from '@aws-amplify/auth';
import { CognitoGroups, ServerStatus } from "common/datamath";
import { ReactInjectable } from "react-utils";

@ReactInjectable()
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(injector: Injector) { }
  groups: string[];
  awsID: string;
  email: string;
  email_verified: boolean;
  name: string;
  async isLoggedIn(retry: boolean = false): Promise<boolean> {

    const session = await fetchAuthSession({ forceRefresh: true }).catch(async () => { await this.login(); });
    if (!session) return false;
    if (!session.tokens?.idToken && !retry) {
      console.log("No id token", session);
      await this.login();
      return await this.isLoggedIn(retry);
    }
    const payload: CognitoIdPayload = session.tokens?.idToken?.payload as any;
    if (!payload) return false;
    console.log(payload);
    this.awsID = payload["cognito:username"];
    this.email = payload["email"];
    this.email_verified = payload["email_verified"];
    return true;
  }
  hasGroup(group: CognitoGroups): boolean {
    return this.groups.indexOf(group) > -1;
  }

  async getToken() {
    return (await fetchAuthSession()).tokens?.idToken?.toString();
  }

  async getHeaders() {
    const creds = JSON.parse(localStorage.getItem("cubes-creds-override") || "null");
    const token = await this.getToken();
    return {
      "id-token": token ?? "",
      ...creds?.awsID ? { "x-aws-id": creds.awsID } : {},
      ...creds?.role ? { "x-postgres-role": creds.role } : {},
      // this overrides role selection for 'the cubeswebdev user group' on 'databases starting with cubeswebdev'
      // this allows devs to work on stuff without being added to production user groups
      // "x-postgres-role": "web_admin",
    };
  }

  async login() {
    await signInWithRedirect()
  }

  async logout() {
    await signOut();
  }

}

export interface CognitoIdPayload {
  "at_hash": string,
  "sub": string,
  "cognito:groups": string[],
  "email_verified": boolean,
  "iss": string,
  "cognito:username": string,
  "origin_jti": string,
  "aud": string,
  "identities": [
    {
      "userId": string,
      "providerName": "Google",
      "providerType": "Google",
      "issuer": null,
      "primary": `${boolean}`,
      "dateCreated": `${string}`
    }
  ],
  "token_use": "id",
  "auth_time": number,
  "name": string,
  "exp": number,
  "iat": number,
  "jti": string,
  "email": string
}