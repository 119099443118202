import * as s from "./cubes-schema";
import { PermissionNames, TABLE_NAMES } from "./cubes-utils";
import { PermissionsOptions, permissions, TableSecurity } from "./cubes-schema-helpers";

function priv(
  tableType: "server" | "ledger" | "record",
  normalDelete: boolean,
  readScope: "user" | "admin",
  writeScope: "user" | "admin"
): PermissionsOptions {
  return { tableType, normalDelete, readScope: `web_${readScope}`, writeScope: `web_${writeScope}` };
}

export const rls = {

  /** Allow access to specific branches or branch groups */
  Branch: new TableSecurity<s.Branch>(permissions.super_admin, x => ({
    remoteTable: s.Branch.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),
  /** Allow access to specific branches or branch groups */
  BranchBillingInfo: new TableSecurity<s.BranchBillingInfo>(permissions.branch_table, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),
  /** Allow access to specific branches or branch groups */
  BranchDiscountLedger: new TableSecurity<s.BranchDiscountLedger>(permissions.ledger, x => {
    x["branch"].Groups.groupID.__;
    x.branch.Groups.groupID.__;
    return ({
      remoteTable: s.Branch.name,
      groups: x.branch.Groups.groupID.__,
      rows: x.branchID.__,
    });
  }),
  /** Allow access to specific branches or branch groups */
  BranchLedger: new TableSecurity<s.BranchLedger>(permissions.ledger),
  /** Allow access to specific branches or branch groups */
  SalesTaxLedger: new TableSecurity<s.SalesTaxLedger>(permissions.ledger),
  /** Allow access to specific branches or branch groups */
  BranchLocations: new TableSecurity<s.BranchLocations>(permissions.branch_table, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,

  })),
  /** Allow access to specific branches or branch groups */
  BranchUnitTypeMarkup: new TableSecurity<s.BranchUnitTypeMarkup>(permissions.branch_table, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),

  /** Allow access to specific branches or branch groups */
  BranchUser: new TableSecurity<s.BranchUser>(permissions.branch_user_join, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),

  CentralDiscountLedger: new TableSecurity<s.CentralDiscountLedger>(permissions.ledger),
  CentralLedger: new TableSecurity<s.CentralLedger>(permissions.ledger),

  Division: new TableSecurity<s.Division>(permissions.super_admin, x => ({
    remoteTable: s.Division.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),

  DivisionBillingInfo: new TableSecurity<s.DivisionBillingInfo>(permissions.client_central, x => ({
    remoteTable: s.Division.name,
    groups: x.division.Groups.groupID.__,
    rows: x.divisionID.__,
  })),

  DivisionDiscountLedger: new TableSecurity<s.DivisionDiscountLedger>(permissions.ledger, x => ({
    remoteTable: s.Division.name,
    groups: x.division.Groups.groupID.__,
    rows: x.divisionID.__,
  })),

  DivisionLedger: new TableSecurity<s.DivisionLedger>(permissions.ledger),
  /** Allow access to specific branches or branch groups */
  OwnerUser: new TableSecurity<s.OwnerUser>(permissions.branch_user_join, x => ({
    remoteTable: s.Owner.name,
    groups: x.owner.Groups.groupID.__,
    rows: x.ownerID.__,
  })),
  Owner: new TableSecurity<s.Owner>(permissions.super_admin, x => ({
    remoteTable: s.Owner.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),
  // dealers are not allowed direct access to owner information
  OwnerBillingInfo: new TableSecurity<s.OwnerBillingInfo>(permissions.owner_table, x => ({
    remoteTable: s.Owner.name,
    groups: x.owner.Groups.groupID.__,
    rows: x.ownerID.__,
  })),
  OwnerLedger: new TableSecurity<s.OwnerLedger>(permissions.ledger),

  Customer: new TableSecurity<s.Customer>(permissions.record_delete, x => ({
    remoteTable: s.Customer.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),
  CustomerBillingInfo: new TableSecurity<s.CustomerBillingInfo>(permissions.client_dealer, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customer.id.__,
  })),
  CustomerOtherContacts: new TableSecurity<s.CustomerOtherContacts>(permissions.client_dealer, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customerID.__,
  })),
  CustomerPaymentInfo: new TableSecurity<s.CustomerPaymentInfo>(permissions.private, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customerID.__,
  })),
  BranchPaymentInfo: new TableSecurity<s.BranchPaymentInfo>(permissions.private, x => ({
    remoteTable: s.Branch.name,
    groups: x.branch.Groups.groupID.__,
    rows: x.branchID.__,
  })),
  OwnerPaymentInfo: new TableSecurity<s.OwnerPaymentInfo>(permissions.private, x => ({
    remoteTable: s.Owner.name,
    groups: x.owner.Groups.groupID.__,
    rows: x.ownerID.__,
  })),
  DivisionPaymentInfo: new TableSecurity<s.DivisionPaymentInfo>(permissions.private, x => ({
    remoteTable: s.Division.name,
    groups: x.division.Groups.groupID.__,
    rows: x.divisionID.__,
  })),
  CustomerLedger: new TableSecurity<s.CustomerLedger>(permissions.ledger, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customerID.__,
  })),

  Rental: new TableSecurity<s.Rental>(permissions.record, x => ({
    remoteTable: s.Customer.name,
    groups: x.customer.Groups.groupID.__,
    rows: x.customerID.__,
  })),
  // {
  //   tableType: "server",
  //   normalDelete: false,
  //   readScope: "web_user",
  //   writeScope: "web_user",
  // }
  Unit: new TableSecurity<s.Unit>({
    tableType: "custom",
    INSERT: "web_admin",
    UPDATE: "web_user",
    DELETE: "web_admin",
    SELECT: "web_user",
  }, x => ({
    remoteTable: s.Unit.name,
    groups: x.Groups.groupID.__,
    rows: x.id.__,
  })),

  Transaction: new TableSecurity<s.Transaction>(permissions.record),
  InvoiceLine: new TableSecurity(permissions.record),
  PaymentLine: new TableSecurity(permissions.record),
  AutopayAttempt: new TableSecurity(permissions.record),
  // CustomerPayment: new TableSecurity({ tableType: "record", normalDelete: true, readScope: "web_user", writeScope: "app" }, {}),

  // these tables are special, because RLS queries are run as the same user as the query itself
  User: new TableSecurity<s.User>(permissions.user_table),
  UserPermission: new TableSecurity<s.UserPermission>(permissions.rls_tables),
  Permission: new TableSecurity(permissions.rls_tables),

  // utility tables that do not use RLS
  Item: new TableSecurity(priv("server", true, "user", "admin")),
  UnitType: new TableSecurity(priv("server", true, "user", "admin")),
  Promotion: new TableSecurity(priv("server", true, "user", "admin")),
  NoticeTemplate: new TableSecurity(priv("server", true, "user", "admin")),

  // currently abandoned tables
  Movement: new TableSecurity(priv("server", true, "user", "admin")),


} satisfies Record<Exclude<TABLE_NAMES, `${string}Group` | `${string}GroupChild`>, TableSecurity<any>>;

Object.keys(rls).forEach(k => { rls[k].table = k; });
interface TableRLS {
  table: { perm: PermissionNames };
  group: { perm: PermissionNames, value: string };
  row: { perm: PermissionNames, value: string };
}


function buildquery() {

}
// CREATE POLICY BranchDiscountLedger_rls_web_central_user
// ON "public"."BranchDiscountLedger" 
// TO cubeswebdev2_web_central_user 
// USING(EXISTS(SELECT 1 
//   FROM "public"."UserPermission"
//   INNER JOIN "public"."Permission" ON "public"."Permission"."id" = "public"."UserPermission"."permID"
//   WHERE user_id = current_setting('rls_security.user_id')::UUID
//     AND (
//       ("public"."Permission"."name" = 'read ledger for all branches')
//       OR
//       ("public"."Permission"."name" = 'read ledger for specific branches' AND (
//         "public"."BranchDiscountLedger"."branchID" = ANY("public"."UserPermission"."value"::UUID[])
//       ))
//       OR 
//       ("public"."Permission"."name" = 'read ledger for groups of branches' AND (EXISTS (SELECT 1 
//       FROM public."Branch"
//         INNER JOIN public."BranchGroupChild"
//       WHERE (public."Branch"."id" = public."BranchDiscountLedger"."branchID")
//         AND (public."BranchGroupChild"."childID" = public."Branch"."id")
//         AND (public."BranchGroupChild"."groupID" = (ANY("public"."UserPermission"."value"::UUID[]))))))
//     )));


//https://www.cybertec-postgresql.com/en/abusing-security-definer-functions/

